header {
    background-color: #7A83B6;
    text-align: center;
    margin-bottom: 20px;
    padding: 5px;
    position: relative; 
  }
  
  header h1 {
    margin: 0;
    font-size: 2.5em;
    color: #ffffff;
    font-family: 'Kodchasan', sans-serif;
  }
  
  header p {
    margin: 0;
    font-size: 1.2em;
    color: #ffffff;
    font-family: 'Judson', sans-serif;
  }
  