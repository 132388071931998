body {
  margin: 0;
  font-family: "Kodchasan", sans-serif;
  background-color: #f0f0f0;
  height: 100vh;
}

.container {
  width: 100%;
  height: 95vh;
  margin: 0;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.horizontal-line {
  border: 2px solid #7a83b6;
  margin-bottom: 20px;
}

.game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #ffffff;
}

.waiting {
  background-color: #4b79a1;
  color: white;
  font-size: 2em;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
}

.player-list {
  margin-top: 20px;
  list-style: none;
  padding: 0;
}

.player-list li {
  background-color: #8ab6d6;
  padding: 10px;
  border-radius: 5px;
  margin: 5px 0;
  color: #333;
}

.chat-box {
  width: 100%;
  max-width: 400px;
  margin: 20px 0;
}

.chat-box input[type="text"] {
  width: calc(100% - 50px);
  padding: 10px;
  border: none;
  border-radius: 5px 0 0 5px;
}

.chat-box button {
  width: 50px;
  padding: 10px;
  border: none;
  background-color: #3b5998;
  color: white;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}

footer {
  background-color: #3b5998;
  color: white;
  padding: 10px 0;
  text-align: center;
  font-size: 0.9em;
  position: fixed;
  width: 100%;
  bottom: 0;
}

.custom-title {
  font-family: "Comic Sans MS", cursive, sans-serif;
  font-size: 2em;
  color: #ffcc00;
  text-shadow: 2px 2px 4px #000000;
}

.start-button-container {
  margin-top: 20px;
}

.title {
  font-size: 2em;
  margin: 20px 0;
}
