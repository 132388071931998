.playtype-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
  padding: 20px;
  color: #ffffff;
  text-align: center;
}

.sub-header {
  flex: 0 1 auto;
  display: flex;
  justify-content: space-between;
  background-color: #7a83b6;
  font-size: 2em;
  margin-bottom: 20px;
  padding: 5px;
}

.superscript-svg {
  display: flex;
  align-items: flex-start;
  font-size: 12px;
}

.superscript-svg svg {
  margin-right: 2px;
  height: 30px;
}

.remaining-text {
  margin-left: 2px;
}

.playtype-flex {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.playtypes {
  display: flex;
  flex-direction: column;
  gap: 30px;
  background-color: #7a83b6;
  font-family: "Kodchasan", sans-serif;
  padding: 90px;
  color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  flex: 1;
  overflow: auto;
}

.playtype-title {
  color: #ffffff;
  font-size: 1.5em;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  border: none;
  padding: 30px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  background: linear-gradient(to bottom, #8a92c4, #6a72a4);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  margin-bottom: 10px;
  display: inline-block;
}

.playtype-title:hover {
  background: linear-gradient(to bottom, #6a72a4, #8a92c4);
}

@media (max-width: 768px) {
  .content {
    flex-direction: column;
  }

  .playtype-flex {
    width: 100%;
  }

  .playtypes {
    flex-grow: 0;
  }
}
