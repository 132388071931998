.select-challenge-section {
  display: flex;
  flex-direction: row;
}

.round-info {
  display: flex;
  flex-direction: column;
  width: 20%;
  padding: 20px;
  color: #ffffff;
  gap: 5px;
}

.round {
  font-size: 1.5em;
  font-family: "Kodchasan", sans-serif;
  background-color: #7a83b6;
  margin-bottom: 20px;
  padding: 10px;
  text-align: center;
}

.players {
  background-color: #7a83b6;
  padding: 10px;
  flex-grow: 1;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.players p {
  font-family: "Judson", sans-serif;
  margin: 5px 0;
  font-size: 1.5em;
}

.score {
  display: flex;
  justify-content: space-around;
  font-family: "Kodchasan", sans-serif;
  font-size: 1em;
  font-weight: bold;
}

.challenge-section {
  display: flex;
  flex-direction: column;
  width: 100%; 
  gap: 5px;
  padding: 20px;
  color: #ffffff;
  text-align: center;
}

.sub-header {
  flex: 0 1 auto;
  display: flex;
  justify-content: space-between;
  background-color: #7a83b6;
  font-size: 2em;
  margin-bottom: 20px;
  padding: 5px;
}

.superscript-svg {
  display: flex;
  align-items: flex-start;
  font-size: 12px;
}

.superscript-svg svg {
  margin-right: 2px;
  height: 30px;
}

.remaining-text {
  margin-left: 2px;
}

.challenges-flex {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.challenges {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  grid-auto-rows: minmax(100px, auto);
  background-color: #7a83b6;
  font-family: "Kodchasan", sans-serif;
  padding: 50px;
  color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  flex: 1;
  overflow: auto;
}

.challenge-title {
  color: #ffffff;
  font-size: 1.5em;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  border: none;
  padding: 10px 20px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 25px;
  background: linear-gradient(to bottom, #8a92c4, #6a72a4);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  margin-bottom: 10px;
  display: inline-block;
}

.challenge-title:hover {
  background: linear-gradient(to bottom, #6a72a4, #8a92c4);
}

@media (max-width: 768px) {
  .content {
    flex-direction: column;
  }

  .challenge-section {
    width: 100%;
  }

  .challenges {
    flex-grow: 0;
  }
}
