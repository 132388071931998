.login-content {
  display: flex;
  flex: 1;
  justify-content: center;
  gap: 5px;
}

.round-info {
  display: flex;
  flex-direction: column;
  width: 20%;
  padding: 20px;
  color: #ffffff;
  gap: 5px;
}

.round {
  font-size: 1.5em;
  font-family: "Kodchasan", sans-serif;
  background-color: #7a83b6;
  margin-bottom: 20px;
  padding: 10px;
  text-align: center;
}

.players {
  background-color: #7a83b6;
  padding: 10px;
  flex-grow: 1;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.players p {
  font-family: "Judson", sans-serif;
  margin: 5px 0;
  font-size: 1.5em;
}

.score {
  display: flex;
  justify-content: space-around;
  font-family: "Kodchasan", sans-serif;
  font-size: 1em;
  font-weight: bold;
}

.lyrics-section-login {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 50%;
  gap: 5px;
  padding: 20px;
  color: #ffffff;
  text-align: center;
}

.playing-now-login {
  background-color: #7a83b6;
  font-family: "Judson", sans-serif;
  font-size: 3em;
  margin-bottom: 20px;
  flex-grow: 1;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.init-btn button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  padding: 10px 20px;
  font-size: 1em;
  background-color: #ffffff;
  color: #7a83b6;
  font-family: "Kodchasan", sans-serif;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, box-shadow 0.3s;
}

.init-btn button:hover {
  background-color: #f0f0f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.login-vinyl {
  animation: spin 2s linear infinite;
}

.login-vinyl svg{
  height: 65vh;
}

@media (max-width: 500px) {
  .content {
    flex-direction: column;
  }

  .round-info,
  .lyrics-section,
  .guesses {
    width: 100%;
  }

  .lyrics {
    flex-grow: 0;
  }
}
