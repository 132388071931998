.multiplayer-content {
  display: flex;
  flex: 1;
  justify-content: space-between;
  gap: 5px;
}

.round-info {
  display: flex;
  flex-direction: column;
  width: 20%;
  padding: 20px;
  color: #ffffff;
  gap: 5px;
}

.round {
  font-size: 1.5em;
  font-family: "Kodchasan", sans-serif;
  background-color: #7a83b6;
  margin-bottom: 20px;
  padding: 10px;
  text-align: center;
}

.players {
  background-color: #7a83b6;
  padding: 10px;
  flex-grow: 1;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.players p {
  font-family: "Judson", sans-serif;
  margin: 5px 0;
  font-size: 1.5em;
}

.score {
  display: flex;
  justify-content: space-around;
  font-family: "Kodchasan", sans-serif;
  font-size: 1em;
  font-weight: bold;
}

.lyrics-section {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 5px;
  padding: 20px;
  color: #ffffff;
  text-align: center;
}

.time-remaining {
  display: flex;
  justify-content: space-between;
  background-color: #7a83b6;
  font-size: 2em;
  margin-bottom: 20px;
  padding: 5px;
}

.superscript-svg {
  display: flex;
  align-items: flex-start;
  font-size: 12px;
}

.superscript-svg svg {
  margin-right: 2px;
  height: 30px;
}

.remaining-text {
  margin-left: 2px;
}

.countdown-timer {
  display: None;
  background-color: #7a83b6;
  font-family: "Judson", sans-serif;
  font-size: 2.5em;
  margin-bottom: 20px;
  flex-grow: 1;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.lyrics {
  height: 55vh;
  background-color: #7a83b6;
  font-family: "Judson", sans-serif;
  font-size: 2.5vw;
  margin-bottom: 20px;
  flex-grow: 1;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.guess-input {
  height: 5vh;
  background-color: #7a83b6;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.guess-input input {
  font-family: "Judson", sans-serif;
  background-color: #7a83b6;
  color: #ffffff;
  padding: 10px;
  font-size: 1em;
  width: 60%;
  border: 1px solid #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border 0.3s, box-shadow 0.3s;
}

.guess-input button {
  padding: 10px 20px;
  font-size: 1em;
  background-color: #ffffff;
  color: #7a83b6;
  font-family: "Kodchasan", sans-serif;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  transition: background-color 0.3s, box-shadow 0.3s;
}

.guess-input button:hover {
  background-color: #f0f0f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.guesses {
  display: flex;
  flex-direction: column;
  width: 20%;
  background-color: #7a83b6;
  font-family: "Kodchasan", sans-serif;
  padding: 20px;
  color: #ffffff;
  gap: 5px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
}

.guess-title {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  border-bottom: 2px solid #ffffff;
  padding-bottom: 10px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}

.guesses p {
  font-family: "Judson", sans-serif;
  margin: 5px 0;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}

.correct-guess,
.incorrect-guess {
  font-size: 1.2em;
  margin-top: 10px;
}

.correct-guess {
  color: green;
}

.incorrect-guess {
  color: red;
}

@media (max-width: 768px) {
  .content {
    flex-direction: column;
  }

  .round-info,
  .lyrics-section,
  .guesses {
    width: 100%;
  }

  .lyrics {
    flex-grow: 0;
  }
}
